import React, { useEffect } from 'react'
import { Link, graphql } from 'gatsby'
// import { useEffect } from "react";
import loadable from '@loadable/component'
import Layout from '../components/Layout'
import Helmet from 'react-helmet'

const ContactCall = loadable(() => import('../components/ContactCall'));
const PartnerRoll2 = loadable(() => import('../components/PartnerRoll2'));

const PaginationLink = props => {
  if (!props.test) {
    return (
      <Link to={`/partners/${props.url}`} className='btnblog'>
        {props.text}
      </Link>
    )
  } else {
    return (
      <span disabled className='btnblogdisabled'>
        {props.text}
      </span>
    )
  }
}


const PartnerPage = (props) => {
  const { pageContext: { first, group, index, last } } = props
  const previousUrl = index - 1 === 1 ? '' : (index - 1).toString()+'/'
  const nextUrl = (index + 1).toString()+'/'
  // const { data } = props
  useEffect(() => {
        document.getElementById('lang-switch').href = 'https://e-multicontent.pl/parnterzy/'
   });

    return (
      <>
      <Helmet titleTemplate="%s">
      <meta name='image' content={'https://e-multicontent.com/img/meta/oferta-meta.jpg'} />

      <meta property="og:description" content={'We invite you to meet our partners! We cooperate with reliable companies providing professional services.'}/>
      <meta property='og:title' content={'Partners | Copywriting Agency | SEO | Translations | e-multicontent.com'} />
      <meta property='og:image' content={'https://e-multicontent.com/img/meta/oferta-meta.jpg'} />
      <meta property="og:url" content={'https://e-multicontent.com/partners/'}/>


      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content="e-multicontent.com" />
      <meta property="twitter:url" content="https://e-multicontent.com/partners/" />
      <meta name="twitter:title" content="Partners | Copywriting Agency | SEO | Translations | e-multicontent.com" />
      <meta name="twitter:description" content="We invite you to meet our partners! We cooperate with reliable companies providing professional services. ✔️" />
      <meta name="twitter:image" content="https://e-multicontent.com/img/apple-touch-icon.png" />
      <script type='application/ld+json'>
      {JSON.stringify({
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [{
          "@type": "ListItem",
          "position": 1,
          "name": "Home",
          "item": "https://e-multicontent.com/"
        },{
          "@type": "ListItem",
          "position": 2,
          "name": "Partners",
          "item": "https://e-multicontent.com/partners/"
        }]
      })}
      </script>

      </Helmet>
      {index === 1 ?
      <Helmet>
      <title>Partners | Copywriting Agency | e-multicontent</title>
      <meta name="description" content={'We invite you to meet our partners! We cooperate with reliable companies providing professional services. ✔️'}/>
      <link rel="canonical" href="https://e-multicontent.com/partners/"  />
      </Helmet>
      :
      <Helmet>
      <title>{'Page ' + index + ' - Partners | Copywriting Agency | e-multicontent.pl '}</title>
      <meta name="robots" content="noindex, nofollow" />
      <meta name="description" content={'We invite you to meet our partners! We cooperate with reliable companies providing professional services. ✔️ Page ' + index}/>
      <link rel="canonical" href={'https://e-multicontent.com/partners/'+index+'/'}  />
      </Helmet>
      }
      <Layout>
      <nav className="breadcrumbs-nav section">
          <Link className="inline-link" to="/">Home</Link>&nbsp;&gt;&nbsp;<span style={{fontWeight:'600'}}><b>Partners</b></span>
      </nav>
      <div
        id="partners"
        className="full-width-image-container"
        style={{
          backgroundPosition: `right bottom`,
          backgroundRepeat: `no-repeat`,
          backgroundSize: 'contain',
          height:'550px',
          marginTop: '100px',
        }}
      >

        </div>



        <h1
          className="has-text-weight-bold is-size-1"
          style={{
            lineHeight: '1',
            padding: '0.35em',
            borderRadius: '10px',
            //boxShadow: '5px 5px 20px rgb(23,19,43)',
            marginTop: '0%',
            display: 'flex',
            flexWrap: 'wrap',
            position: 'absolute',
            left: '7%',
            top: '200px',
            zIndex:'1000',
            textAlign: 'left',
            backgroundColor:'rgba(255,255,255,0.75)'




          }}
        >
        Our&nbsp; <b style={{color:'#36b3d2'}}> partners</b>
        <br></br>
        </h1>
        <br></br>
        <h2
        className="mright"
        style={{
          color: 'rgb(23,19,43)',
          lineHeight: '1',
          padding: '0.35em',
          borderRadius: '10px',
          //boxShadow: '5px 5px 20px rgb(23,19,43)',
          marginTop: '0%',
          display: 'block',
          position: 'absolute',
          left: '8%',
          flexWrap: 'wrap',
          top: '350px',
          backgroundColor:'rgba(255,255,255,0.75)',
          textAlign: 'left',}} >We cordially invite you to meet our partners! <br></br><br></br>
          </h2>

          <p className="ux" align="center" ><a className="btnoutline" href="#partnerzy">⇩</a></p>

          <div id="partnerzy" style={{marginTop:'-20px',backgroundColor:'rgba(255,255,255,0.7)'}}>

          <br></br>




          <div style={{marginTop:'0px',marginLeft:'3%',backgroundColor:'rgba(255,255,255,0.7)'}}>
          <br></br>
            <p style={{maxWidth:'1000px',margin:'2%',padding:'0% 2% 0% 2%',borderLeft:'5px solid #36b3d2'}}>
            Good support is invaluable on the way to success. Especially when it comes from reliable business partners.
              <br /><br />
            We cooperate with reliable companies providing professional services in various areas of the e-commerce industry. Together, comprehensively, we will not only strengthen your company's position in the domestic market but also help in foreign expansion.
            <br /><br />
            <strong style={{color:'#36b3d2'}}>Check out our partners!</strong>
            </p>
            <div style={{paddingTop:'30px', width:'100%',textAlign:'center',marginBottom:'0px',margin:'auto'}}>
            <PartnerRoll2 posts={group} />
            </div>
            <div className='buttons is-centered' style={{marginTop:'25px'}}>
              <PaginationLink test={first} url={previousUrl} text='<' />
              <PaginationLink test={last} url={nextUrl} text='>' />
            </div>
          </div>
          </div>



          <br></br>
          <br></br>
          <ContactCall />




          {/*kontakt

          <div style={{backgroundColor:'rgb(245, 245, 245)',padding:'5%'}}>


          <p className="is-size-2" style={{fontWeight:'800'}}>
          <Typewriter
              onInit={(typewriter) => {
                typewriter.typeString("<span style='color:#36b3d2'>Skontaktuj się</span> z nami:")
                  .pauseFor(2500)
                  .deleteAll()
                  .start();
              }}
              options={{
                autoStart: true,
                loop: true,
              }}
            />
          </p>
          <br />
          <br />


          <div className="columns" style={{margin:'auto'}}>

          <div className="column">
          <h3 style={{fontSize:'1em',textAlign:'center'}}>Chcesz się z nami skontaktować?<br /> Wypełnij formularz poniżej, możesz również załączyć plik.</h3>
          <br />
          <FormMsg/>
          </div>

          <div className="column" style={{position:'relative',textAlign:'center'}}>
          <h3 style={{fontSize:'1em'}}>Chcesz otrzymać ofertę dla siebie?<br />Wypełnij brief.</h3>
          <br /><br />
          <a className="btn" style={{fontSize:'1.5em'}}
          href="https://docs.google.com/forms/d/e/1FAIpQLSdkSrYDjdS0qddoWvk7555qSST6N5Z4qpBcrVWoy7aZQ2_SqA/viewform"
          target="_blank" rel="noopener noreferrer">Brief e-multicontent &nbsp; &nbsp;
          <img width="15px" height="22px"  style={{verticalAlign:'middle', marginRight:'5px'}}   alt="brief" src='/img/angle-right.svg'/></a>
          <br /><br />
          <br /><br />
          <Img fluid={data.file.childImageSharp.fluid}
          imgStyle={{objectFit:'contain'}}
          alt="Contact us z e-multicontent - copywriting, tłumaczenia, seo, strony internetowe, e-commerce, marketplace"
          style={{
            padding: "0",
            borderRadius: "10px",width:'100%',minHeight:'244px',
            maxWidth:'550px', border:'3px solid #f5f5f5',margin:'auto',marginBottom:'25px',
            backgroundColor: 'white', objectFit:'contain',objectPosition:'top center'}} />
          </div>

          </div>

          <div className="columns" style={{marginTop: '50px', paddingBottom: '70px',marginLeft:'3%',textAlign:'center' }}>

          <h3 style={{marginLeft:'3%', color:'#222222'}}>Chcesz wysłać maila?</h3>&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;<h4><a href="mailto:info@e-multicontent.com" rel="noopener noreferrer" className="btn"  style={{marginTop:'-30px',marginBottom:'20px'}} target="_blank">info@e-multicontent.com</a></h4>


          <h3 style={{marginLeft:'3%', color:'#222222'}}>Czy może wolisz porozmawiać?</h3>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<h4><a href="tel:+48713074517" rel="noopener noreferrer" style={{marginTop:'-30px'}}  className="btn" target="_blank">+48 71 307 45 17</a></h4>
          </div>
          <div style={{width:'80%',marginLeft:'10%',marginRight:'10%',textAlign:'center'}}>
          <Link className="btn" to="/"> Home </Link>&nbsp;&nbsp;&nbsp;
          <Link to="/o-nas/" className="btn">O nas</Link>&nbsp;&nbsp;&nbsp;
          <Link className="btn" to="/blog/"> Blog </Link>&nbsp;&nbsp;&nbsp;
          <Link to="/portfolio/" className="btn"> Portfolio </Link> &nbsp;&nbsp;&nbsp;
          <Link to="/contact-us/" className="btn"> Contact us </Link> &nbsp;&nbsp;&nbsp;
          <Link to="/kariera/" className="btn"> Kariera </Link> &nbsp;&nbsp;&nbsp;
          <a className="btn" href="#newsletter"> Newsletter </a>&nbsp;&nbsp;&nbsp;
          <Link className="btn" to="/ebook/"> E-book </Link>&nbsp;&nbsp;&nbsp;
          </div>
          </div>




          <div
            className="full-width-image-container twenty lazy-background"
            style={{
              backgroundColor: '#36b3d2',
              backgroundPosition: `bottom left`,
              backgroundSize:'cover',
              backgroundRepeat:'no-repeat',
              height:'420px',
              marginBottom:'-50px',
              marginTop:'0px',
            }}
            loading="lazy"
          ></div>*/}



      </Layout>
      </>
    )
  }


export default PartnerPage


export const query = graphql`
  query FilesPa{
    file(relativePath: {regex: "/optimized/kontakt.jpg/"}) {
      childImageSharp {
        fluid(fit: CONTAIN) {
        srcWebp
        srcSetWebp
        src
        srcSet
      }
      }
    }
  }
`
